import templateUrl from './submission.html'
import './submission.scss'
import SubmissionConfig from './submission.config'

const MAX_PREFERRED_EXPERTS = 20

const SubmissionComponent = {
  bindings: {
    experiment: '<',
    variation: '<'
  },
  templateUrl,
  controller: class Submission {
    constructor (ApiLogging, AuthService, AttachmentsService, $cookies, CouponService, Flash, Modal, $location, $timeout, SegmentAnalytics, SubmissionService, $scope, $state, $mdMedia, $rootScope, $window, $anchorScroll, UserService, ModalService, moment, Configuration, SubmissionDraftService, ExperimentService) {
      'ngInject'
      this._identify = 'SubmissionComponent'
      this.apiLogging = ApiLogging
      this.attachmentService = AttachmentsService
      this.couponService = CouponService
      this.flash = Flash
      this.Modal = Modal
      this.$cookies = $cookies
      this.$location = $location
      this.$scope = $scope
      this.service = SubmissionService
      this.segmentAnalytics = SegmentAnalytics
      this.SegmentAnalyticsService = SegmentAnalytics
      this.$state = $state
      this.$timeout = $timeout
      this.$mdMedia = $mdMedia
      this.$rootScope = $rootScope
      this.$window = $window
      this.$anchorScroll = $anchorScroll
      this.UserService = UserService
      this.ModalService = ModalService
      this.Configuration = Configuration
      this.AuthService = AuthService
      this.SubmissionDraftService = SubmissionDraftService
      this.ExperimentService = ExperimentService

      this.consultationPrice = Configuration.features.pricing.consultationPrice

      this.isInitialized = false
      this.authForm = {
        type: 'register'
      }
      this.callbacks = {
        goToStep: this.goToStep,
        getProjectType: this.getProjectType,
        getProjectSubject: this.getProjectSubject
      }
      this.showSubmissionStyleSwitcher = false
      this.partner = false
      this.submissionConfig = {}

      this.coupon_code = this.$cookies.get('coupon_code')
      this.codeableConsierge = 36482 // Consierge user ID
      this.data = {
        title: $location.search().title ? $location.search().title : '',
        description: '',
        websiteUrl: null,
        urgency: '2',
        duedate: moment().add(5, 'days').format(),
        preferredContractors: {
          selected: [],
          options: []
        },
        fixedBudget: 'no',
        invitePreferredExpert: false,
        budget: -12,
        exactBudget: null,
        consultation: false,
        publishForAll: false,
        pro: false,
        project_type: $location.search().project_type,
        project_subject: $location.search().project_subject,
        // Attachments
        attachments: [],
        queuedattachments: [],
        attachmentsQueueCtrl: null,
        partner_name: null,
        experiment: null,
        MAX_PREFERRED_EXPERTS: MAX_PREFERRED_EXPERTS
      }
      this.experts = {}
      this.title = 'Submit your project in less thanx 10 minutes'
      this.processing = false
      this.serverErrors = []
      this.spinnerText = ''
      this.task = null
      this.user = {
        email: '',
        password: ''
      }

      this.isPartnerForm = false
      this.stepOneVariant = null // set to null so no variant is displayed until value returned by Optimizely
      this.step = 'one'
      this.substep = 'intro'
      this.processingStatus = null
    }

    /**
     * On init lifecycle event
     * @return {[type]} [description]
     */
    $onInit () {
      this.init()
    }

    populateTestData () {
      // TODO: use new variant data
      this.substep = 'budget'
      this.setSubmissionConfig(this.partner)
    }

    onpopstateHandler (event) {
      console.log('[SubmissionComponent] > onpopstateHandler', this.step, this.substep, event)
      if (this.step === 'two') {
        this.step = 'one'

        // handle substep back redirect for variant and if it was a consultation or not
        const tmpSubstep = this.data.project_type === 'consultation' ? 'one' : 'two'
        this.substep = this.stepOneVariant === 'on' ? tmpSubstep : null
        console.log('[SubmissionComponent] > onpopstateHandler > redirect to previous step', this.step, this.substep)
      } else if (this.step === 'one' && this.stepOneVariant === 'on') {
        if (this.substep === 'type') {
          this.goToSubstep('intro')
        } else if (this.substep === 'details') {
          this.goToSubstep('type')
        } else if (this.substep === 'budget') {
          this.goToSubstep('details')
        } else if (this.substep === 'consultation') {
          this.goToSubstep('intro')
        } else if (this.substep === 'auth') {
          if (this.data.project_type === 'consultation') {
            this.goToSubstep('consultation')
          } else {
            this.goToSubstep('budget')
          }
        }

        console.log('[SubmissionComponent] > onpopstateHandler > redirect to previous substep', this.step, this.substep)
      }
    }
    async redirectToV3 () {
      const origin = this.$window.location.origin
      const queryString = this.$window.location.search

      return this.$timeout(() => {
        // redirect for local development
        if (!this.Configuration.isBuild && origin.includes('app.cdbl.local')) {
          this.$window.location.href = `http://app.cdbl.local:9002${queryString}`
        } else if (this.Configuration.isBuild) {
          // redirect for environments
          this.$window.location.href = `${origin}/v2/project-submission${queryString}`
        }
      }, 350)
    }
    /**
     * Form submission init
     * @return {[type]} [description]
     */
    async init () {
      // Set default form config
      this.setSubmissionConfig()
      this.experimentPredictedBudget = false

      console.log('[SubmissionComponent] > init > query params', this.$location.search())
      // Get referral partner and overwrite the default form config
      const refParam = this.$location.search().ref
      console.log('[SubmissionComponent] > init > get referral partner >', refParam)

      await this.service.getReferralPartner(refParam).then(response => {
        console.log('[SubmissionComponent] > getReferralPartner > response', response)
        const isCustomPartnerForm = response.content && response.content.length > 0 // has form related custom data
        if (isCustomPartnerForm) {
          this.partner = response.partner.name
          this.allowedPartner = response.partner.name
          this.allowedPartnerConfig = SubmissionConfig[this.allowedPartner]
          this.showSubmissionStyleSwitcher = true
          this.isPartnerForm = true
          this.setSubmissionConfig(this.partner)
        }

        // Modify v2 form for partners where we need to hide preferred expert field.
        if (response.fields && !response.fields.preferrable) {
          this.partnerFormOverride()
        }
      }, err => {
        console.log('[SubmissionComponent] > getReferralPartner > err > set default form', err)
        this.setSubmissionConfig()
      }).catch(err => {
        console.log('[SubmissionComponent] > getReferralPartner > catch err', err)
        this.setSubmissionConfig()
      }).finally(async () => {
        console.log('[SubmissionComponent] > getReferralPartner > finally > set variant if on default codable form only', this.partner)

        // EXPERIMENT
        if (!this.isPartnerForm) {
          const accountCreatedBySubmissionV3 = this.UserService.user && this.UserService.user.accountCreatedBySubmissionV3
          if (accountCreatedBySubmissionV3) {
            await this.redirectToV3()
          } else if (!this.UserService.isAuthenticated() && this.ExperimentService.isOn('project-submission-v3.1')) {
            await this.redirectToV3()
          }

          // TODO: cleanup the experiment - for now just hardcode the variant
          this.stepOneVariant = 'on'
          this.segmentAnalytics.trackSubmissionFormLoaded({
            partnerName: this.partner
          })
          this.setSubmissionConfig() // reapply new config
        } else {
          console.log('[SubmissionComponent] > $onInit > partner form >', this.partner, ' > use control')
          this.stepOneVariant = 'off'
          this.segmentAnalytics.trackSubmissionFormLoaded({
            partnerName: this.partner
          })
          this.setSubmissionConfig(this.partner) // reapply new config
        }
        // -----
      })

      console.log('[SubmissionComponent] > init > after referral partner', this.partner)

      if (this.$cookies.get('utm_campaign')) {
        this.data.utmCampaign = this.$cookies.get('utm_campaign')
        this.data.utmMedium = this.$cookies.get('utm_medium')
        this.data.utmSource = this.$cookies.get('utm_source')
      }

      if (this.$location.search().utm_campaign) {
        this.data.utmCampaign = this.$location.search().utm_campaign
        this.data.utmMedium = this.$location.search().utm_medium
        this.data.utmSource = this.$location.search().utm_source
      }

      // Preselect budget and team if param in URL
      if (this.$location.search().team && this.experimentPredictedBudget) {
        this.data.pro = true
        this.data.budget = -5 // not sure
        this.data.fixedBudget = 'yes'
      }


      // Preselect exact budget
      if (this.$location.search().exactBudget && this.experimentPredictedBudget) {
        let exactBudget = Number.parseInt(this.$location.search().exactBudget)
        // enforce max/min values for custom budget
        exactBudget = Math.min(exactBudget, this.Configuration.features.submission.budgetCustom.max)
        exactBudget = Math.max(exactBudget, this.Configuration.features.submission.budgetCustom.min)

        this.data.fixedBudget = 'yes'
        this.data.budget = 'custom'
        this.data.exactBudget = exactBudget
      }

      // Prepopulate description
      if (this.$location.search().desc) {
        this.data.description = this.$location.search().desc
      }

      if (this.$cookies.get('coupon_code') || this.$location.search().coupon_code) {
        if (!this.UserService.isAuthenticated() && !this.$location.search().utm_campaign) {
          this.data.utmCampaign = 'referral'
          this.data.utmMedium = 'app'
          this.data.utmSource = 'codeable'
        }
        if (this.UserService.isAuthenticated()) {
          this.flash.show('views/flashes/coupon_code_logged_in.html', true, {})
        } else {
          this.flash.show('views/flashes/coupon_code.html', true, {})
        }
      }

      if (!this.$cookies.get('utm_campaign') && !this.$location.search().utm_campaign && this.$cookies.get('referrer')) {
        this.data.utmCampaign = this.$cookies.get('referrer')
        this.data.utmMedium = 'web'
        this.data.utmSource = 'backlink'
      }

      if (this.data.project_type && this.data.project_subject) {
        if (this.projectIsValid(this.data.project_type, this.data.project_subject)) {
          this.goToStep('two')
        }
      }

      // if (this.$location.search().preferredContractor) {
      //   this.service.getExpert(this.$location.search().preferredContractor).then(expert => {
      //     if (expert) {
      //       this.data.preferredContractors.selected.push({id: expert.id, name: expert.fullName});
      //     }
      //   });
      // }

      this.service.getExperts().then(experts => {
        this.experts = experts
        this.experts.all = experts.all.filter(expert => expert.id !== this.codeableConsierge)
        this.experts.normal = experts.normal.filter(expert => expert.id !== this.codeableConsierge)
        this.experts.pro = experts.pro.filter(expert => expert.id !== this.codeableConsierge)
        console.log('experts', experts, this.experts)
        this.data.preferredContractors.options = experts.normal


        // Set preferred experts from url
        const preferredString = this.$location.search().preferredContractor

        console.log('[SubmissionComponent] > experts:', this.experts.all, 'preferredString:', preferredString)
        if (preferredString) {
          const preferredArray = preferredString.split(',')
          console.log('[SubmissionComponent] > preferredArray:', preferredArray)
          preferredArray.forEach(preferredId => {
            const preferredExpert = this.experts.all.find(expert => expert.id.toString() === preferredId)
            console.log('[SubmissionComponent] > preferredExpert:', preferredExpert)
            if (preferredExpert &&
                this.data.preferredContractors.selected.length < MAX_PREFERRED_EXPERTS &&
                !this.data.preferredContractors.selected.find(expert => expert.id === preferredExpert.id)) {
              this.data.preferredContractors.selected.push(preferredExpert)
            }
          })

          if (this.data.preferredContractors.selected.length > 0) {
            this.data.invitePreferredExpert = true
            this.preferredExpertsFromUrl = [...this.data.preferredContractors.selected] // TODO: will be refactored, for now this is a hack solution (due to tech debt of supporting v1 and v2, v2 was built as experiment on top of v1 and it is not separate) to make v2 drafts aware of preferred expert was applied via url param
          }
          if (preferredString && this.data.preferredContractors.selected.length === 0) {
            this.data.invitePreferredExpert = true
            this.data.preferredContractorsNotFound = true
          }
        }
      })

      this.isInitialized = true

      // Catch backbutton event and reset step
      this.$window.onpopstate = event => {
        this.$timeout(() => {
          this.onpopstateHandler(event)
        })
      }
    }

    partnerFormOverride () {
      // TODO: plug-in BE or FE config and apply changes to form here.
      this.data.hidePreferredExpert = true  // hide preferredExpert field on codeableVariant form

      // Always default on codeableVariant v2 form - for new partners (ie Automattic)
      this.showSubmissionStyleSwitcher = false
      this.isPartnerForm = false
      this.setSubmissionConfig('codeableVariant')
    }

    setSubmissionConfig (partner) {
      let config = SubmissionConfig['codeable']

      // process experiment data
      if (this.stepOneVariant === 'on') {
        config = SubmissionConfig['codeableVariant']
      }

      if (partner) {
        config = SubmissionConfig[partner]
      }


      this.submissionConfig = config
      console.log('[SubmissionComponent] > setSubmissionConfig > ', partner, config)
    }

    setSubmissionStyle (partnerName) {
      // reset data ?
      this.data.project_type = null
      this.data.project_subject = null

      this.partner = partnerName
      this.setSubmissionConfig(partnerName === false ? 'codeable' : partnerName)
      this.segmentAnalytics.trackSubmissionFormLoaded({
        partnerName: this.partner,
        trigger: 'on_toggle'
      })
    }

    /**
     * Get Project Type
     * @param  {string} project_type Project type
     * @return {object}              [description]
     */

    getProjectType (project_type, config) {
      config = config || this.submissionConfig
      return config.types.find(projectType => {
        return projectType.project_type === project_type
      })
    }

    /**
     * Get Project Subject
     * @param  {string} project_type    Project type
     * @param  {string} project_subject Project subject
     * @return {object}                 [description]
     */
    getProjectSubject (project_type, project_subject, config) {
      let type = this.getProjectType(project_type, config)

      if (type.options) {
        return type.options.find(option => {
          return option.value === project_subject
        })
      }

      return false
    }

    /**
     * Banner Get Project Subject
     * @param  {string} project_type    Project type
     * @param  {string} project_subject Project subject
     * @return {object}                 [description]
     */
    getBannerProjectSubject (project_type, project_subject, config) {
      config = config || this.submissionConfig
      const type = config.types.find(projectType => {
        return (projectType.project_type === project_type && projectType.project_subject === project_subject)
      })

      return type || false
    }

    /**
     * Banner Get Project Subject
     * @param  {string} project_type    Project type
     * @param  {string} project_subject Project subject
     * @return {object}                 [description]
     */
    getBannerProjectType (project_type) {
      let type = this.getProjectType(project_type)
      return type
    }

    /**
     * Go to step
     * @param  {string} step step
     * @TODO: check different inplementation
     */
    goToStep (step) {
      this.step = step
      this.$anchorScroll('mainContent')
    }

    /**
     * Go to substep if suported by parent step
     * @param  {string} substep
     * @TODO: check different inplementation
     */
    goToSubstep (substep) {
      // Handle history state
      if (this.substep === 'intro' && substep === 'type') {
        this.$window.history.pushState(null, '')
      }

      if (this.substep === 'type' && substep === 'details') {
        this.$window.history.pushState(null, '')
      }

      if (this.substep === 'details' && substep === 'budget') {
        this.$window.history.pushState(null, '')
      }

      if (this.substep === 'budget' && substep === 'auth') {
        this.$window.history.pushState(null, '')
      }

      if (this.substep === 'consultation' && substep === 'auth') {
        this.$window.history.pushState(null, '')
      }

      this.substep = substep
      this.$anchorScroll('mainContent')
    }

    /**
     * Set step to two
     * @param  {string} project_type    Project type
     * @param  {string} project_subject Project subject
     */
    goToStepTwo (project_type, project_subject) {
      this.data.project_type = project_type
      this.data.project_subject = project_subject
      this.data.partner_name = this.partner
      this.segmentAnalytics.trackProjectTypeSelected(this.data)

      if (this.step === 'one') {
        console.log('[SubmissionComponent] > add history state', this.$window.history.length)
        this.$window.history.pushState(null, '')
      }

      this.goToStep('two')
    }
    /**
     * Is step
     * @param  {string}  step
     * @return {Boolean} Is step
     */
    isStep (step) {
      return this.step === step
    }

    turnIntoAConslutation () {
      this.data.project_subject = 'consultation'
      this.data.project_type = 'consultation'
    }
    /**
     * Is project valid
     * @param  {string} project_type    Project type
     * @param  {string} project_subject Project subject
     * @return {boolean}                Project valid
     */
    projectIsValid (project_type, project_subject) {
      if (this.getProjectType(project_type) && (this.getProjectSubject(project_type, project_subject) || this.getBannerProjectSubject(project_type, project_subject))) {
        return true
      }

      return false
    }

    /**
     * Publish project
     * Actions:
     * - register/login User
     * - create new task
     * @return {[type]} [description]
     */
    async publishProject () {
      this.processing = true
      let publishProjectFailed = false
      const referralMeta = {
        productRef: this.$location.search().prod,
        customerRef: this.$location.search().cust,
        customerId: this.$location.search().cust_id
      }

      // Update partner name for case that submission flow skips step 1
      this.data.partner_name = this.partner

      // Add kind parameter to data if it exists in config
      const projectType = this.getProjectType(this.data.project_type)
      if (projectType && projectType.kind) {
        this.data.kind = projectType.kind
      }

      // console.log('payload', this.service.modifyTaskDataBeforePublish(this.data));
      // return;

      // ==================================
      // Authenticate user
      // ==================================
      if (this.$cookies.get('coupon_code')) {
        this.user.couponCode = this.$cookies.get('coupon_code')
      }

      if (this.$location.search().coupon_code) {
        this.user.couponCode = this.$location.search().coupon_code
      }
      if (!this.UserService.isAuthenticated()) {
        this.spinnerText = 'Authenticating...'
        this.processingStatus = 'authenticate'
        if (this.authForm.type === 'register') {
          console.log('[SubmissionComponent] > publishProject > referralCode', this.$location.search().ref)
          console.log('[SubmissionComponent] > publishProject > referralMeta', referralMeta)

          this.user.fullName = this.user.firstName + ' ' + this.user.lastName
          await this.AuthService.register(this.user, referralMeta)
            .then(userData => {
              console.log('[SubmissionComponent] > publishProject > user > register > data', userData)
              this.segmentAnalytics.trackAccountCreated(userData)
            }).catch(err => {
              if (err.data) {
                let errors = err.data.errors
                // errors.forEach(error => {
                //   this.serverErrors.push(error);
                // });
                this.serverErrors = errors
                this.spinnerText = ''
                this.processing = false
                publishProjectFailed = true
                this.processingStatus = 'error'
              }
            })
        }

        if (this.authForm.type === 'sign in') {
          await this.AuthService.login(this.user)
            .catch(err => {
              if (err.data) {
                let errors = err.data.errors
                // errors.forEach(error => {
                //   this.serverErrors.push(error);
                // });
                this.serverErrors = errors
                this.spinnerText = ''
                this.processing = false
                publishProjectFailed = true
                this.processingStatus = 'error'
              }
            })
        }

        // check if user is expert and prevent further actions
        if (this.UserService.isAuthenticated() && this.UserService.isExpert()) {
          alert('Posting a project with an expert account is not allowed. Redirecting to dashboard...')
          this.segmentAnalytics.trackEvent('User tried to post a project with expert account')
          this.$state.go('dashboard.list')
          return
        }
      }

      // TODO rethink this
      // ========================
      if (publishProjectFailed) {
        return
      }

      this.spinnerText = 'Please wait, creating task...'
      this.processingStatus = 'create'

      const vm = this
      this.$window.onbeforeunload = function () {
        this.SegmentAnalyticsService.trackEvent('user navigated away while posting a project', { value: 'external' })
        return 'Your project is publishing, please wait.'
      }

      this.$scope.$on('$stateChangeStart', function (event, toState, toParams, fromState, fromParams) {
        if (vm.processing === true) {
          this.SegmentAnalyticsService.trackEvent('user navigated away while posting a project', { value: 'internal' })
          if (vm.$window.confirm('Your project is publishing, please wait.')) {
            event.preventDefault()
          }
        }
      })

      // ==================================
      // Coupon code
      // ==================================
      if (this.user.couponCode) {
        await this.couponService.addCoupon(this.user.couponCode).then(() => {
          this.$cookies.remove('coupon_code')
        }).catch(err => {
          console.log(err)
          this.$cookies.remove('coupon_code')
        })
      }

      // ==================================
      // Segment
      // ==================================
      if (this.$cookies.segment) {
        this.service.segment({ segment: this.$cookies.segment }).then(function () {
          this.$cookies.remove('segment')
        }).catch(function () {
          this.$cookies.remove('segment')
        })
      }

      // ==================================
      // Create task
      // ==================================

      if (this.$location.search().affix) {
        this.data.title += this.$location.search().affix
      }

      if (this.$location.search().prefix) {
        this.data.title = this.$location.search().prefix + this.data.title
      }

      await this.service.createTask(this.data).then(async response => {
        this.SubmissionDraftService.removeDraftId()

        let task = response.data
        this.task = task
        // Analytics
        // ============================
        console.log('[SubmissionComponent] > publishProject > post > response', task)
        // API loging
        this.apiLogging.info('task.new', 'created', task)
        // Remove cookie store
        this.$cookies.remove('referrer')

        // Update localstorage user object - tasks count
        // ==============================
        const oldTasksCount = this.UserService.me.tasksCount ? this.UserService.me.tasksCount : 0
        this.UserService.updateUser({
          tasksCount: oldTasksCount + 1 // so that user sees the submenu and potential first project info page
        })
      }).catch(err => {
        console.log('[SubmissionComponent] > publishProject > POST > Failed')
        if (err.data) {
          let errors = err.data.errors
          this.serverErrors = errors
        }
        this.segmentAnalytics.trackTaskPostingFailed(this.data, 'POST')
        this.apiLogging.error('task.new', 'created', err)
        this.processing = false
        this.spinnerText = ''
        this.processingStatus = 'error'
      })


      // ==================================
      // Update Task
      // ==================================
      this.spinnerText = 'Please wait, publishing task...'
      this.processingStatus = 'publish'
      await this.service.updateTask(this.task).then(response => {
        let task = response.data
        this.$scope.$broadcast('modelChangesInitialModelChange', task) // set new model for modelChangesDir
        console.log('[SubmissionComponent] > publishProject > put > response', task)
        this.segmentAnalytics.trackTaskPosted(task, this.data) // this.data.partner_name is from form data, this. task is from response from BE

        this.apiLogging.info('task.new', 'published', task)

        // if first project
        if (typeof this.UserService.getFirstProjectFlag() === 'undefined') { // this.UserService.me.tasksCount === 1
          this.UserService.setFirstProjectFlag({
            id: task.id,
            projectType: task.projectType
          })
        }
      }).catch(err => {
        if (err.data) {
          let errors = err.data.errors
          this.serverErrors = errors
        }
        this.segmentAnalytics.trackTaskPostingFailed(this.task, 'PUT')
        this.apiLogging.error('task.new', 'published', err)
        this.spinnerText = ''
        this.processing = false
        this.processingStatus = 'error'
      })

      // ==================================
      // Upload Attachments task
      // ==================================
      if (this.data.attachmentsQueueCtrl && this.data.attachmentsQueueCtrl.queueList.length) {
        console.log('attachmentsQueueCtrl > uploader > start', this.task.id, this.data.attachmentsQueueCtrl)
        this.spinnerText = 'Please wait, uploading files...'
        this.processingStatus = 'upload'
        await this.data.attachmentsQueueCtrl
          .uploader(this.task.id)
          .then(response => {
            console.log('attachmentsQueueCtrl > uploader > response', response)
          })
          .catch(err => {
            console.log('attachmentsQueueCtrl > uploader > err', err)
            this.apiLogging.error('task.new', 'created', err)
            this.processing = false
            this.spinnerText = ''
            this.processingStatus = 'error'
          })
          .finally(() => {
            console.log('attachmentsQueueCtrl > uploader > finally')
          })
        this.$timeout()
      }
      // if (this.data.attachments.length > 0) {
      //   this.spinnerText = 'Please wait, uploading files...'
      //   this.processingStatus = 'upload'
      //   await this.attachmentService.uploadQueuedFiles(this.data.queuedattachments, this.data.attachments, { id: this.task.id }, this.$scope)
      //     .then(response => {})
      //     .catch(err => {
      //       console.log(err)
      //       this.apiLogging.error('task.new', 'uploadAtachments', err, { authorizationToken: this.AuthService.getAuthToken() })
      //     })
      // }

      this.processing = false
      this.processingStatus = 'complete'

      this.$window.onbeforeunload = function () {
        return null
      }

      this.$state.go('tasks.display', {
        id: this.task.id,
        intro: true
      })
    }

    toggleSteps () {
      this.step = this.step === 'one' ? 'two' : 'one'
    }
  }
}

export default SubmissionComponent
